import React from "react"
import classnames from "classnames"

const A = ({ href, className, external, children, ...otherProps }) => (
  <a
    className={classnames(
      "text-green-700 font-body pt-2 font-light cursor-pointer print:text-black",
      className
    )}
    target={external ? "_blank" : undefined}
    rel={external ? "noopener noreferrer" : undefined}
    href={href}
    {...otherProps}
  >
    {children}
  </a>
)

export default A
