import React from "react"
import classnames from "classnames"
import { Link } from "gatsby"

const StyledLink = ({ to, className, external, children, ...otherProps }) => (
  <Link
    className={classnames(
      "font-body text-xl text-green-700 pt-2 font-light cursor-pointer",
      className
    )}
    to={to}
    {...otherProps}
  >
    {children}
  </Link>
)

export default StyledLink
