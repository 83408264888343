import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Menu from "react-burger-menu/lib/menus/slide"

import A from "./typography/a"
import Link from "./typography/menu/link"

import "./main.css"

const Layout = ({ children }) => {
  const {
    file: { publicURL: logoSrc },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "icon.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <div>
      <Menu right>
        <Link to="/">Home</Link>
        <Link to="/#what">What do we do?</Link>
        <Link to="/#who">Who are we?</Link>
        <Link to="/#reco">Recommendations</Link>
        <Link to="/#contact">Contact us</Link>
        <Link to="/blog" partiallyActive={true}>
          Blog
        </Link>
      </Menu>
      <main>{children}</main>
      <footer
        className="px-4 py-8 font-display print:hidden"
        style={{ background: "#E0D9CA" }}
      >
        <div className="max-w-4xl mx-auto">
          <p>All rights reserved © Noima SASU 2019</p>
          <p>Website hosted on Netlify</p>
          <p>
            Visual for the header based on Sarah Dorweiler picture{" "}
            <A href="https://unsplash.com/photos/9Z1KRIfpBTM" external>
              The beauty of nature
            </A>
          </p>
          <img
            className="mt-8 mb-4"
            src={logoSrc}
            alt="Noima logo"
            height="100"
            width="100"
          />
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
