import React from "react"
import classnames from "classnames"

const P = ({ children, className, ...otherProps }) => (
  <p
    className={classnames("font-body text-xl font-light pt-2", className)}
    {...otherProps}
  >
    {children}
  </p>
)

export default P
