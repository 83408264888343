import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"

const baseClassNames =
  "font-body text-xl text-green-700 pt-2 font-light cursor-pointer"

const anchorStyle = ({ location, href }) => {
  const [loc, anchor] = href.split("#")

  if (anchor) {
    return location.pathname === loc
      ? { className: classnames(baseClassNames, "pl-4") }
      : { style: { display: "none" } }
  }

  return {
    className: classnames(baseClassNames, {
      "font-semibold": location.pathname === loc,
    }),
  }
}

const MenuLink = ({ children, to, className, ...otherProps }) => {
  return (
    <Link
      activeClassName="font-semibold"
      to={to}
      getProps={anchorStyle}
      {...otherProps}
    >
      {children}
    </Link>
  )
}

export default MenuLink
