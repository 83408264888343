import React from "react"
import classnames from "classnames"

const H3 = ({ className, children, ...otherProps }) => (
  <h3
    className={classnames("font-body text-2xl font-semibold pt-4", className)}
    {...otherProps}
  >
    {children}
  </h3>
)

export default H3
